import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import styled, { keyframes } from 'styled-components';
import firstbornImage from '../../assets/images/deities/firstbornsoulbound.png';
import './DeityPathPage.css';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';

// Updated animation for a more solemn feel - gold to midnight blue
const undeathGradient = keyframes`
  0%, 100% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
`;

// Text shadow animation for normal text (non-gradient)
const textPulse = keyframes`
  0%, 100% { text-shadow: 0 0 8px rgba(255, 215, 0, 0.3), 0 0 15px rgba(255, 215, 0, 0.15); }
  50% { text-shadow: 0 0 12px rgba(143, 188, 255, 0.3), 0 0 20px rgba(143, 188, 255, 0.15); }
`;

// Border animation for containers
const borderGlow = keyframes`
  0%, 100% { border-color: rgba(255, 215, 0, 0.5); box-shadow: 0 0 15px rgba(0, 0, 0, 0.7), 0 0 5px rgba(255, 215, 0, 0.2); }
  50% { border-color: rgba(143, 188, 255, 0.4); box-shadow: 0 0 15px rgba(0, 0, 0, 0.7), 0 0 5px rgba(143, 188, 255, 0.2); }
`;

const StyledFirstbornPage = styled.div`
  background: #080808;
  color: #E5E4E2;
  min-height: 100vh;
  position: relative;

  .blue-text {
    background: linear-gradient(45deg, #ffd700, #8fbcff, #c2a642, #ffd700);
    background-size: 300% 300%;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: ${undeathGradient} 12s ease infinite;
    filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.9));
  }

  &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: 
      linear-gradient(
        45deg,
        rgba(0, 0, 0, 0.98),
        rgba(10, 10, 10, 0.97)
      ),
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M12 2L14.5 5L12 8L9.5 5M12 16L14.5 19L12 22L9.5 19M2 12L5 9.5L8 12L5 14.5M16 12L19 9.5L22 12L19 14.5' stroke='rgba(143, 188, 255, 0.05)' fill='none' stroke-width='1'/%3E%3C/svg%3E"),
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cpath d='M10 10H30V30H10z' stroke='rgba(255, 215, 0, 0.06)' fill='none' stroke-width='1'/%3E%3C/svg%3E");
    background-size: 100%, 24px 24px, 40px 40px;
    z-index: -1;
  }

  @media (max-width: 768px) {
    &::before {
      background-size: 100%, 16px 16px, 30px 30px;
    }
  }

  .deity-path-title h1 {
    font-size: 3.5rem;
    background: linear-gradient(45deg, #ffd700, #8fbcff, #c2a642, #ffd700);
    background-size: 300% 300%;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: ${undeathGradient} 12s ease infinite;
    text-transform: uppercase;
    letter-spacing: 4px;
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.9));
    position: relative;
    z-index: 1;
    margin-bottom: 2rem;
    word-wrap: break-word;
    overflow-wrap: break-word;
    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
    line-height: 1.2;
    
    @media screen and (max-width: 768px) {
      font-size: 2.5rem;
      letter-spacing: 2px;
    }
    
    @media screen and (max-width: 480px) {
      font-size: 2rem;
      letter-spacing: 1px;
    }
    
    &::after {
      content: '';
      position: absolute;
      bottom: -10px;
      left: 0;
      width: 100%;
      height: 2px;
      background: linear-gradient(90deg, transparent, #8fbcff, #ffd700, #8fbcff, transparent);
    }
  }

  .content-block, .trait {
    background: linear-gradient(135deg, #0a0a0a, #131313);
    border: 1px solid;
    border-image: linear-gradient(to right, rgba(255, 215, 0, 0.5), rgba(143, 188, 255, 0.35)) 1;
    box-shadow: 
      0 4px 15px rgba(0, 0, 0, 0.8),
      inset 0 0 15px rgba(0, 0, 0, 0.9);
    transition: all 0.3s ease;
    margin: 2rem 0;
    padding: 2rem;
    position: relative;
    overflow: hidden;
    border-radius: 4px;

    @media (max-width: 768px) {
      padding: 1.5rem;
      margin: 1.5rem 0;
    }

    &:hover {
      animation: ${borderGlow} 3s ease infinite;
      transform: translateY(-3px);
    }
  }

  h2 {
    font-size: 2rem;
    background: linear-gradient(45deg, #ffd700, #8fbcff, #c2a642, #ffd700);
    background-size: 300% 300%;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: ${undeathGradient} 12s ease infinite;
    margin-bottom: 1rem;
    position: relative;
    display: inline-block;
    filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.9));
    
    @media (max-width: 768px) {
      font-size: 1.8rem;
    }
    
    &::after {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 100%;
      height: 2px;
      background: linear-gradient(90deg, rgba(255, 215, 0, 0.5), rgba(143, 188, 255, 0.35));
    }
  }

  h3 {
    font-size: 1.5rem;
    background: linear-gradient(45deg, #ffd700, #8fbcff, #c2a642);
    background-size: 300% 300%;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: ${undeathGradient} 12s ease infinite;
    margin: 1rem 0;
    filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.9));
    
    @media (max-width: 768px) {
      font-size: 1.3rem;
    }
  }

  h4 {
    font-size: 1.2rem;
    color: #ffd700;
    animation: ${textPulse} 6s ease-in-out infinite;
    margin: 1rem 0;
    
    @media (max-width: 768px) {
      font-size: 1.1rem;
    }
  }

  p {
    color: #e0e0e0;
    line-height: 1.8;
    font-size: 1.1rem;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
    letter-spacing: 0.02em;
    position: relative;
    z-index: 2;
    
    @media (max-width: 768px) {
      font-size: 1rem;
      line-height: 1.6;
    }
  }

  strong {
    background: linear-gradient(45deg, #ffd700, #8fbcff);
    background-size: 300% 300%;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: ${undeathGradient} 8s ease infinite;
    font-weight: bold;
    filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.9));
  }

  ul {
    margin: 1rem 0;
    padding-left: 1.5rem;
    list-style-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14'%3E%3Cpath d='M7 2 L10 7 L7 12 L4 7 Z' fill='%238fbcff' fill-opacity='0.6'/%3E%3C/svg%3E");
    
    @media (max-width: 768px) {
      padding-left: 1.2rem;
    }
  }

  li {
    color: #e0e0e0;
    line-height: 1.6;
    margin: 0.5rem 0;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
    padding-left: 0.5rem;
    
    @media (max-width: 768px) {
      line-height: 1.5;
      margin: 0.4rem 0;
    }
    
    &::marker {
      color: rgba(143, 188, 255, 0.7);
    }
  }

  .trait-header {
    position: relative;
    padding-left: 1rem;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 3px;
      height: 0;
      background: linear-gradient(to bottom, #ffd700, #8fbcff);
      transition: height 0.3s ease;
    }

    &:hover::before {
      height: 100%;
      box-shadow: 0 0 8px rgba(143, 188, 255, 0.4);
    }
  }

  .requirements-section {
    background: linear-gradient(135deg, #050505, #0a0a0a);
    border: 1px solid;
    border-image: linear-gradient(to right, rgba(255, 215, 0, 0.5), rgba(143, 188, 255, 0.35)) 1;
    box-shadow: 
      0 0 20px rgba(0, 0, 0, 0.8), 
      inset 0 0 15px rgba(0, 0, 0, 0.8);
    position: relative;
    overflow: hidden;
    border-radius: 4px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: 
        url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='60' height='60' viewBox='0 0 60 60'%3E%3Cpath d='M30 15 C35 15 40 20 40 25 C40 30 35 35 30 35 C25 35 20 30 20 25 C20 20 25 15 30 15 Z M30 20 C32.5 20 35 22.5 35 25 C35 27.5 32.5 30 30 30 C27.5 30 25 27.5 25 25 C25 22.5 27.5 20 30 20 Z M15 30 L20 25 L25 30 L20 35 Z M35 30 L40 25 L45 30 L40 35 Z M30 45 L35 40 L40 45 L35 50 Z' stroke='rgba(143, 188, 255, 0.1)' fill='none'/%3E%3C/svg%3E");
      opacity: 0.3;
      z-index: 0;
    }
  }

  .dark-block {
    background: linear-gradient(135deg, #030303, #080808);
    border: 1px solid;
    border-image: linear-gradient(to right, rgba(255, 215, 0, 0.5), rgba(143, 188, 255, 0.35)) 1;
    position: relative;
    overflow: hidden;
    border-radius: 4px;
  }

  .veiled-paths h2 {
    background: linear-gradient(45deg, #ffd700, #8fbcff, #c2a642, #ffd700);
    background-size: 300% 300%;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: ${undeathGradient} 12s ease infinite;
    filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.9));
  }

  .veiled-link {
    color: #e0e0e0;
    text-decoration: none;
    transition: all 0.3s ease;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
    display: block;
    padding: 8px 0;

    &:hover {
      color: #ffd700;
      animation: ${textPulse} 4s ease-in-out infinite;
      transform: translateX(3px);
    }
  }

  .specializations-list {
    list-style: none;
    padding-left: 1.5rem;

    li {
      position: relative;
      margin-bottom: 1rem;
      color: #D4D4D4;
      transition: transform 0.2s ease;

      &::before {
        content: "◈";
        color: #8fbcff;
        position: absolute;
        left: -1.5rem;
        opacity: 0.8;
        transition: color 0.3s ease;
      }

      &:hover {
        transform: translateX(5px);
        
        &::before {
          opacity: 1;
          color: #ffd700;
        }
      }
    }
  }

  .veiled-paths {
    h2 {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      transition: color 0.3s ease;

      &:hover {
        transform: scale(1.02);
      }
    }
  }

  .trait {
    position: relative;
  }

  .image-modal {
    background: rgba(0, 0, 0, 0.95);
    backdrop-filter: blur(10px);

    .modal-image {
      box-shadow: 0 0 30px rgba(255, 215, 0, 0.2), 0 0 25px rgba(143, 188, 255, 0.2);
      border: 1px solid;
      border-image: linear-gradient(to right, rgba(255, 215, 0, 0.5), rgba(143, 188, 255, 0.35)) 1;
    }

    .modal-close,
    .modal-download {
      background: rgba(0, 0, 0, 0.8);
      border: 1px solid;
      border-image: linear-gradient(to right, rgba(255, 215, 0, 0.5), rgba(143, 188, 255, 0.35)) 1;
      color: #FFD700;
      transition: all 0.3s ease;

      &:hover {
        background: rgba(143, 188, 255, 0.15);
        color: #FFFFFF;
        border-image: linear-gradient(to right, rgba(255, 215, 0, 0.8), rgba(143, 188, 255, 0.6)) 1;
      }
    }
  }

  .deity-path-background {
    filter: brightness(0.9) contrast(1.2) saturate(1.1);
    transition: filter 0.3s ease;
    position: relative;
    border: 2px solid;
    border-image: linear-gradient(to right, rgba(255, 215, 0, 0.6), rgba(143, 188, 255, 0.4)) 1;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.8);

    &:hover {
      filter: brightness(1) contrast(1.3) saturate(1.3);
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.9), 0 0 15px rgba(143, 188, 255, 0.25);
      border-image: linear-gradient(to right, rgba(255, 215, 0, 0.8), rgba(143, 188, 255, 0.6)) 1;
    }
  }
`;

const pageVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.5 }
};

const contentVariants = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.7, ease: "easeOut" }
};

const traitVariants = {
  initial: { opacity: 0, x: -20 },
  animate: { opacity: 1, x: 0 },
  transition: { duration: 0.5, ease: "easeOut" }
};

const FirstbornSoulbound = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showFullImage, setShowFullImage] = useState(false);

  // The sequence of pages for navigation - following dropdown menu order
  const pageSequence = [
    '/deities/caelumbris/shadowed',
    '/deities/firstborn/soulbound',
    '/deities/libris/knowledge',
    '/deities/nebula/mistwalker',
    '/deities/queenmab/frozenveil',
    '/deities/queen-titania/wildgrowth',
    '/deities/sacrathor/dominion',
    '/deities/unbound',
    '/deities/valdis/justice',
    '/deities/wildscar/emberfury'
  ];

  return (
    <StyledFirstbornPage className="deity-path-page">
      <motion.div 
        className="deity-path-page"
        variants={pageVariants}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        <motion.div 
          className="deity-path-hero"
          variants={contentVariants}
        >
          <img 
            src={firstbornImage} 
            alt="Firstborn: Soulbound Path" 
            className="deity-path-background"
            onClick={() => setShowFullImage(true)}
            style={{ cursor: 'pointer' }}
          />
          <div className="deity-path-title">
            <h1>Firstborn: The Soulbound Path</h1>
          </div>
        </motion.div>

        <motion.div 
          className="content-block dark-block"
          variants={contentVariants}
        >
          <h2 className="blue-text">Firstborn: Soulbound Covenant</h2>
          <p>
            In the depths of mortality's end and the silence beyond, the Firstborn reigns as the impartial arbiter of oaths and balance. As the God of Death and Pacts, the Firstborn ensures that every promise, every solemn vow, and every shared bond is honored without fail. For those who follow this path, no act is more sacred than forging a pact—an immutable covenant that binds souls with divine precision. Every agreement, regardless of its purpose, carries the weight of inevitability; breaking a vow invites dire and unyielding consequences.
          </p>
        </motion.div>

        <motion.div 
          className="content-block requirements-section"
          variants={contentVariants}
        >
          <div className="edicts">
            <h2>Edicts</h2>
            <ul>
              <li>Uphold All Oaths: Honor every oath, contract, and verbal agreement without exception.</li>
              <li>Respect the Natural Order: Intervene only when the balance between life and death is disrupted; destroy mindless undead.</li>
              <li>Foster Pacts: Create or arbitrate pacts that promote mutual growth and maintain equilibrium.</li>
            </ul>
          </div>
          
          <div className="requirements">
            <h2>Requirements</h2>
            <ul>
              <li>Absolute Commitment: Every agreement entered must be honored, even at great personal cost.</li>
              <li>Active Mediation: Seek opportunities to mediate disputes and ensure fairness in all pacts.</li>
              <li>Undead Restriction: Never raise undead creatures except to serve as guardians of sacred sites or to fulfill the terms of a pact.</li>
            </ul>
          </div>
        </motion.div>

        <motion.div 
          className="trait"
          variants={traitVariants}
        >
          <div className="trait-header">
            <h3>The Firstborn's Domain</h3>
          </div>
          <p>
            The Firstborn's realm lies at the nexus of life and death—a stark expanse where the weight of every promise ever made presses upon the soul. Towering monoliths, etched with the pacts of countless beings, stretch endlessly into the void, their inscriptions glowing faintly with divine energy. The air thrums with the resonance of myriad vows, whispered eternally in the silence of this solemn place.
          </p>
          <p>
            To traverse this domain is to feel the inescapable gravity of accountability. Every shadow echoes with the memory of broken oaths, and every step tests the resolve of those who dare walk this path. Here, the Firstborn's gaze pierces all deceptions, ensuring that every pact is upheld with unwavering finality.
          </p>
        </motion.div>

        <h2>Levels and Abilities</h2>
        <motion.div 
          className="trait"
          variants={traitVariants}
        >
          <div className="trait-header">
            <h3>Level 3: Oathbound Initiate</h3>
          </div>
          <motion.div 
            className="trait"
            variants={traitVariants}
          >
            <div className="trait-header">
              <h3>Soulbound Pact</h3>
            </div>
            <p>
              You can forge a Soulbound Pact with a number of creatures equal to your proficiency bonus. Each pact lasts for 8 hours and requires mutual consent from all participants. The Firstborn enforces each agreement impartially, and breaking a pact incurs immediate and severe consequences for violators.
            </p>          
            <h4>Breaking the Pact</h4>
            <ul className="specializations-list">
              <li><strong>Penalties for All Violators:</strong> Breaking the terms of a pact results in a temporary reduction to maximum hit points equal to the violator's level plus 2× your proficiency bonus. This penalty lasts for a number of days equal to the violator's level.</li>
              <li><strong>Additional Penalties for Initiators:</strong> If the initiator breaks the pact, they lose the ability to forge new pacts and temporarily lose one character level—and all associated benefits—for a number of days equal to their level.</li>
            </ul>

            <h4>Pact Benefits</h4>
            <p>Each Soulbound Pact confers a divine bonus equal to your proficiency bonus, applied to the chosen benefit. When you forge a pact, choose one of the following types—each with its own benefit and requirement:</p>
            
            <h4>Pact of Combat</h4>
            <ul className="specializations-list">
              <li><strong>Benefit:</strong> Each participant gains a divine bonus to attack and damage rolls.</li>
              <li><strong>Requirement:</strong> All members must actively contribute to combat, whether through direct attacks, providing buffs, or other meaningful actions in the midst of battle.</li>
            </ul>

            <h4>Pact of Defense</h4>
            <ul className="specializations-list">
              <li><strong>Benefit:</strong> Each participant gains a divine bonus to AC and resistance to piercing, slashing, and bludgeoning damage.</li>
              <li><strong>Requirement:</strong> Participants must prioritize defending one another—through casting protective spells, employing abilities to shield allies, or forming defensive positions on the battlefield.</li>
            </ul>

            <h4>Pact of Resolve</h4>
            <ul className="specializations-list">
              <li><strong>Benefit:</strong> Each participant gains a divine bonus to one saving throw of their choice (select from Strength, Dexterity, Constitution, Intelligence, Wisdom, or Charisma).</li>
              <li><strong>Requirement:</strong> Participants must actively work together to overcome hazards—sharing resources, combining abilities, or supporting each other through challenging encounters.</li>
            </ul>

            <h4>Pact of Mastery</h4>
            <ul className="specializations-list">
              <li><strong>Benefit:</strong> Each participant gains a divine bonus to skill checks.</li>
              <li><strong>Requirement:</strong> Participants must collaborate on skill challenges, ensuring that no individual attempts critical tasks alone unless absolutely necessary.</li>
            </ul>

            <h4>Pact of Community</h4>
            <ul className="specializations-list">
              <li><strong>Benefit:</strong> Each participant gains temporary hit points equal to twice your proficiency bonus plus their level, along with advantage on death saving throws.</li>
              <li><strong>Requirement:</strong> Participants must foster teamwork, honesty, and mutual support, avoiding selfish actions that could undermine group cohesion.</li>
            </ul>
          </motion.div>

          <motion.div 
            className="trait"
            variants={traitVariants}
          >
            <div className="trait-header">
              <h3>Pactforged Endurance</h3>
            </div>
            <p>While part of a Soulbound Pact, you gain temporary hit points equal to your proficiency bonus at the start of each of your turns. Additionally, if a Soulbound Pact is broken, all remaining members gain advantage on saving throws against the violator's abilities until the violator's penalty expires.</p>
          </motion.div>
        </motion.div>

        <motion.div 
          className="trait"
          variants={traitVariants}
        >
          <div className="trait-header">
            <h3>Level 5: Covenant Warden</h3>
          </div>
          <motion.div 
            className="trait"
            variants={traitVariants}
          >
            <div className="trait-header">
              <h3>Guardian Spirits</h3>
            </div>
            <p>A number of ethereal guardians equal to your proficiency bonus surround you, shielding you from harm. Each guardian grants a +1 bonus to AC. When you are hit, one guardian dissipates and so does it benefits. These spirits renew each dawn.</p>
          </motion.div>

          <motion.div 
            className="trait"
            variants={traitVariants}
          >
            <div className="trait-header">
              <h3>Chains of Fate</h3>
            </div>
            <p>When you hit a creature with an attack or spell, you can summon ethereal shimmering chains around it, binding the target with the memory of every broken promise. For a number of rounds equal to your proficiency bonus, the target suffers disadvantage on all d20 rolls. At the end of each turn, the target may make a Wisdom saving throw to break free from this mental torment. You can use this ability a number of times equal to your proficiency bonus per long rest.</p>
            <p>If a creature fails two consecutive Wisdom saving throws against Chains of Fate, it becomes incapacitated until the end of its next turn.</p>
            <p>Any creature under Chains of Fate that attempts to teleport, plane shift, or escape magically must succeed on a Wisdom saving throw or be stunned until the end of its next turn.</p>
          </motion.div>
        </motion.div>

        <motion.div 
          className="trait"
          variants={traitVariants}
        >
          <div className="trait-header">
            <h3>Level 10: Oathbound Harbinger</h3>
          </div>
          <motion.div 
            className="trait"
            variants={traitVariants}
          >
            <div className="trait-header">
              <h3>Spirit Storm</h3>
            </div>
            <p>The souls bound to the Firstborn fill the area, wailing in lament. A 15-foot radius around you becomes engulfed in howling spirits. Creatures of your choice within the area take 6d6 psychic and necrotic damage at the start of their turn. You can use this ability a number of times equal to your proficiency bonus per long rest.</p>
          </motion.div>

          <motion.div 
            className="trait"
            variants={traitVariants}
          >
            <div className="trait-header">
              <h3>Phantom Tribunal</h3>
            </div>
            <p>Within the radius of Spirit Storm, the voices of those bound by the Firstborn judge every participant. Allies who currently have an active pact with you gain advantage on saving throws, while those who have broken a vow suffer disadvantage on saving throws.</p>
          </motion.div>
        </motion.div>

        <motion.div 
          className="trait"
          variants={traitVariants}
        >
          <div className="trait-header">
            <h3>Level 15: Champion of Fate</h3>
          </div>
          <motion.div 
            className="trait"
            variants={traitVariants}
          >
            <div className="trait-header">
              <h3>Eternal Form</h3>
            </div>
            <p>As a bonus action, you may shift between three forms.</p>

            <h4>Mortal Form (Normal)</h4>
            <p>You remain in your natural state, bound by your pacts and mortality, and gain the following benefits:</p>
            <ul className="specializations-list">
              <li><strong>Reactive Mitigation:</strong> When you take damage, you can use your reaction to reduce that damage by an amount equal to your proficiency bonus.</li>
              <li><strong>Supportive Presence:</strong> Allies within 10 feet of you gain a bonus to saving throws equal to half your proficiency bonus (rounded down).</li>
              <li><strong>Emergency Vitality:</strong> Once per long rest, when your hit points drop below 50% of your maximum, you may use a bonus action to regain hit points equal to twice your proficiency bonus.</li>
              <li><strong>Resolute Counter:</strong> You may reroll a saving throw a number of times equal to your proficiency bonus, after seeing the result but before its outcome is determined.</li>
            </ul>

            <h4>Spirit Form</h4>
            <p>In Spirit Form, you become incorporeal and gain a flying speed of 60 feet. You also gain resistance to all non-magical damage, necrotic damage, and cold damage, immunity to disease, and the ability to move through solid objects and creatures. (If you end your turn inside an object, you are shunted to the nearest unoccupied space and take 1d10 force damage for every 5 feet moved.)</p>
            <ul className="specializations-list">
              <li><strong>Enhanced Offense:</strong> When you hit a creature with an attack, you deal an extra 1d8 psychic damage. The target must then succeed on a Wisdom saving throw (DC = your ability modifier + proficiency bonus) or lose its reaction until the start of its next turn.</li>
              <li><strong>Evasive Reaction:</strong> Once per turn, when an enemy makes an attack roll against you, you can use your reaction to impose disadvantage on that attack roll.</li>
              <li><strong>Intangible Shift:</strong> You ignore extra movement costs from difficult terrain, and opportunity attacks against you are made with disadvantage.</li>
            </ul>

            <h4>Elite Skeletal Form</h4>
            <p>In Elite Skeletal Form, your AC becomes 18 + your Dexterity modifier, and you gain resistance to piercing and cold damage as well as immunity to necrotic damage and disease.</p>
            <ul className="specializations-list">
              <li><strong>Augmented Attacks:</strong> Your melee attacks deal an extra 1d6 necrotic damage and count as magical for the purpose of overcoming resistances.</li>
              <li><strong>Frightful Aura:</strong> Any hostile creature that starts its turn within 10 feet of you must succeed on a Wisdom saving throw (DC = your ability modifier + proficiency bonus) or become frightened until the start of its next turn.</li>
              <li><strong>Life Reclamation:</strong> When you reduce a creature to 0 hit points with a melee attack, you regain hit points equal to your proficiency bonus.</li>
              <li><strong>Bone-Shattering Roar (Recharge 5–6):</strong> As an action, you unleash a roar. Every hostile creature within 15 feet must succeed on a Constitution saving throw (DC = your ability modifier + proficiency bonus) or become frightened until the end of its next turn and take 2d8 necrotic damage.</li>
            </ul>
          </motion.div>
        </motion.div>

        {showFullImage && (
          <motion.div 
            className="image-modal"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => setShowFullImage(false)}
          >
            <motion.div 
              className="modal-image-container"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ type: "spring", stiffness: 200, damping: 20 }}
            >
              <img 
                src={firstbornImage} 
                alt="Firstborn: Soulbound Path"
                className="modal-image"
              />
              <button 
                className="modal-close"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFullImage(false);
                }}
              >
                ✕
              </button>
              <a 
                href={firstbornImage}
                download="Firstborn.png"
                onClick={(e) => e.stopPropagation()}
                className="modal-download"
              >
                ⤓
              </a>
            </motion.div>
          </motion.div>
        )}
      </motion.div>
    </StyledFirstbornPage>
  );
};

export default FirstbornSoulbound;