import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import LoginPage from './login-page/LoginPage';
import { AuthProvider, useAuth } from './context/AuthContext';
import Navigation from './components/Navigation';

// Import First Veil pages
import Help from './pages/Help';
import History from './pages/History';
import HouseRules from './pages/HouseRules';
import SpellsAndAbilities from './pages/SpellsAndAbilities';
import Prologue from './pages/Prologue';

// Import Deity Components
import CaelumbrisShadowed from './pages/deities/CaelumbrisShadowed';
import LibrisKnowledge from './pages/deities/LibrisKnowledge';
import LibrisKnowledgeTrue from './pages/deities/LibrisKnowledgeTrue';
import FirstbornSoulbound from './pages/deities/FirstbornSoulbound';
import NebulaMistWalker from './pages/deities/NebulaMistWalker';
import QueenMabFrozenVeil from './pages/deities/QueenMabFrozenVeil';
import QueenTitaniaWildgrowth from './pages/deities/QueenTitaniaWildgrowth';
import SacrathorDominion from './pages/deities/SacrathorDominion';
import Unbound from './pages/deities/Unbound';
import ValdisJustice from './pages/deities/ValdisJustice';
import WildscarEmberFury from './pages/deities/WildscarEmberFury';
import LibrisAgreement from './pages/deities/useragreement';

// Import Dashboard components
import CommonerDashboard from './dashboard/CommonerDashboard';
import AdventurerDashboard from './dashboard/AdventurerDashboard';
import AdminDashboard from './dashboard/AdminDashboard';

// Import Pantheon Components
import PantheonCaelumbris from './pages/deities/PantheonCaelumbris';
import PantheonCalanthar from './pages/deities/PantheonCalanthar';
import PantheonLeviathan from './pages/deities/PantheonLeviathan';
import PantheonFirstborn from './pages/deities/PantheonFirstborn';
import PantheonLibris from './pages/deities/PantheonLibris';
import PantheonNebula from './pages/deities/PantheonNebula';
import PantheonQueenMab from './pages/deities/PantheonQueenMab';
import PantheonQueenTitania from './pages/deities/PantheonQueenTitania';
import PantheonSacrathor from './pages/deities/PantheonSacrathor';
import PantheonValdis from './pages/deities/PantheonValdis';
import PantheonWildscar from './pages/deities/PantheonWildscar';

// Import Classes
import ClassesBurningSeed from './pages/classes/ClassesBurningSeed';
import ClassesDronewright from './pages/classes/ClassesDronewright';
import ClassesGlacialHeart from './pages/classes/ClassesGlacialHeart';
import ClassesPathOfWrath from './pages/classes/ClassesPathOfWrath';
import ClassesPyromancer from './pages/classes/ClassesPyromancer';
import ClassesSeraphicGuardian from './pages/classes/ClassesSeraphicGuardian';
import ClassesSovereignZealot from './pages/classes/ClassesSovereignZealot';
import ClassesUmbralInfiltrator from './pages/classes/ClassesUmbralInfiltrator';
import ClassesWayOfTheLifedrinker from './pages/classes/ClassesWayOfTheLifedrinker';
import ClassesWinterbornKnight from './pages/classes/ClassesWinterbornKnight';

// Import Species
import SpeciesGravewalker from './pages/species/SpeciesGravewalker';
import SpeciesHumanity from './pages/species/SpeciesHumanity';
import SpeciesLifedrinker from './pages/species/SpeciesLifedrinker';
import SpeciesManaforged from './pages/species/SpeciesManaforged';
import SpeciesScytherai from './pages/species/SpeciesScytherai';
import SpeciesSeraphiend from './pages/species/SpeciesSeraphiend';
import SpeciesSkinWalker from './pages/species/SpeciesSkinWalker';
import SpeciesStarborn from './pages/species/SpeciesStarborn';
import SpeciesSunseed from './pages/species/SpeciesSunseed';
import SpeciesVermisCorpus from './pages/species/SpeciesVermisCorpus';
import SpeciesWinterWitch from './pages/species/SpeciesWinterWitch';

import './App.css';

// Create a simple Dashboard component to replace the deleted one
const Dashboard = ({ initialTab }) => {
  const { currentUser } = useAuth();
  
  // Render different dashboard based on user role
  switch (currentUser.role) {
    case 'admin':
      return <AdminDashboard initialTab={initialTab} />;
    case 'adventurer':
      return <AdventurerDashboard initialTab={initialTab} />;
    case 'commoner':
    default:
      return <CommonerDashboard initialTab={initialTab} />;
  }
};

// Protected Route component - simplified but handles initial loading
const ProtectedRoute = ({ children, requiredRole }) => {
  const { currentUser, loading } = useAuth();

  // While the initial auth check is happening, render nothing or a minimal loader
  if (loading) {
    // Return null or a very minimal loader to prevent rendering routes prematurely
    return null; 
  }
  
  // After loading, check for user
  if (!currentUser) {
    // Redirect to login if not authenticated
    return <Navigate to="/login" replace />; 
  }

  // Check role if required
  if (requiredRole && currentUser.role !== requiredRole && currentUser.role !== 'admin') {
    // Redirect to dashboard if role doesn't match
    return <Navigate to="/dashboard" replace />; 
  }

  // Render the protected component if authenticated and authorized
  return children;
};

// Main App component
function App() {
  return (
    <Router>
      <AuthProvider>
        {/* AppContent now directly consumes context */}
        <AppContent /> 
      </AuthProvider>
    </Router>
  );
}

// App content - simplified, relies on ProtectedRoute for auth checks
function AppContent() {
  const { currentUser, loading } = useAuth(); // Use loading state here
  const [pageTransition, setPageTransition] = useState('fade-in');
  const location = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  // Render null or a global loading screen ONLY during the *initial* load
  if (loading) {
    return (
      <div className="loading-screen">
        <div className="loading-spinner"></div>
        <p>Initializing Realm...</p>
      </div>
    );
  }

  // Once initial loading is done, render the main structure
  return (
    <div className={`App ${pageTransition} ${currentUser?.role || ''}`}>
      {/* Navigation only appears if logged in (checked via currentUser) */}
      {currentUser && (currentUser.role === 'adventurer' || currentUser.role === 'admin') && (
        <Navigation />
      )}
      <div className="content">
        <Routes>
          {/* Login route: Render LoginPage if not logged in, otherwise redirect */}
          <Route 
            path="/login" 
            element={!currentUser ? <LoginPage /> : <Navigate to="/dashboard" replace />}
          />
          
          {/* Dashboard and other protected routes use ProtectedRoute */}
          <Route 
            path="/dashboard" 
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          {/* ... other protected routes ... */}
          <Route path="/spells-and-abilities" element={
            <ProtectedRoute requiredRole="adventurer">
              <SpellsAndAbilities />
            </ProtectedRoute>
          } />
          <Route path="/help" element={<ProtectedRoute><Help /></ProtectedRoute>} />
          <Route path="/history" element={<ProtectedRoute><History /></ProtectedRoute>} />
          <Route path="/house-rules" element={<ProtectedRoute><HouseRules /></ProtectedRoute>} />
          <Route path="/prologue" element={<ProtectedRoute><Prologue /></ProtectedRoute>} />
          <Route path="/dashboard/profile" element={<ProtectedRoute><Dashboard initialTab="overview" /></ProtectedRoute>} />
          <Route path="/dashboard/account" element={<ProtectedRoute><Dashboard initialTab="account" /></ProtectedRoute>} />
          
          {/* Nested protected routes */}
          <Route path="/deity/*" element={<ProtectedRoute requiredRole="adventurer"><DeityRoutes /></ProtectedRoute>} />
          <Route path="/pantheon/*" element={<ProtectedRoute requiredRole="adventurer"><PantheonRoutes /></ProtectedRoute>} />

          {/* Publicly accessible class/species routes (assuming they don't need login) */}
          <Route path="/classes/*" element={<ClassRoutes />} />
          <Route path="/species/*" element={<SpeciesRoutes />} />

          {/* Default route: Redirects based on auth status */}
          <Route 
            path="*" 
            element={<Navigate to={currentUser ? "/dashboard" : "/login"} replace />}
          />
        </Routes>
      </div>
      {/* Removed the global loading overlay here - handled by initial loading check */}
    </div>
  );
}

// Helper components for nested routes (if not already defined)
const DeityRoutes = () => (
  <Routes>
    <Route path="caelumbris-shadowed" element={<CaelumbrisShadowed />} />
    <Route path="libris-knowledge" element={<LibrisKnowledge />} />
    <Route path="libris-knowledge-true" element={<LibrisKnowledgeTrue />} />
    <Route path="firstborn-soulbound" element={<FirstbornSoulbound />} />
    <Route path="nebula-mist-walker" element={<NebulaMistWalker />} />
    <Route path="queen-mab-frozen-veil" element={<QueenMabFrozenVeil />} />
    <Route path="queen-titania-wildgrowth" element={<QueenTitaniaWildgrowth />} />
    <Route path="sacrathor-dominion" element={<SacrathorDominion />} />
    <Route path="unbound" element={<Unbound />} />
    <Route path="valdis-justice" element={<ValdisJustice />} />
    <Route path="wildscar-ember-fury" element={<WildscarEmberFury />} />
    <Route path="libris-agreement" element={<LibrisAgreement />} />
    {/* Add a default or index route if needed */}
  </Routes>
);

const PantheonRoutes = () => (
  <Routes>
    <Route path="caelumbris" element={<PantheonCaelumbris />} />
    <Route path="calanthar" element={<PantheonCalanthar />} />
    <Route path="leviathan" element={<PantheonLeviathan />} />
    <Route path="firstborn" element={<PantheonFirstborn />} />
    <Route path="libris" element={<PantheonLibris />} />
    <Route path="nebula" element={<PantheonNebula />} />
    <Route path="queen-mab" element={<PantheonQueenMab />} />
    <Route path="queen-titania" element={<PantheonQueenTitania />} />
    <Route path="sacrathor" element={<PantheonSacrathor />} />
    <Route path="valdis" element={<PantheonValdis />} />
    <Route path="wildscar" element={<PantheonWildscar />} />
  </Routes>
);

// Assuming ClassRoutes and SpeciesRoutes components exist to handle these paths
const ClassRoutes = () => (
  <Routes>
    <Route path="ClassesBurningSeed" element={<ClassesBurningSeed />} />
    <Route path="ClassesDronewright" element={<ClassesDronewright />} />
    <Route path="ClassesGlacialHeart" element={<ClassesGlacialHeart />} />
    <Route path="ClassesPathOfWrath" element={<ClassesPathOfWrath />} />
    <Route path="ClassesPyromancer" element={<ClassesPyromancer />} />
    <Route path="ClassesSeraphicGuardian" element={<ClassesSeraphicGuardian />} />
    <Route path="ClassesSovereignZealot" element={<ClassesSovereignZealot />} />
    <Route path="ClassesUmbralInfiltrator" element={<ClassesUmbralInfiltrator />} />
    <Route path="ClassesWayOfTheLifedrinker" element={<ClassesWayOfTheLifedrinker />} />
    <Route path="ClassesWinterbornKnight" element={<ClassesWinterbornKnight />} />
  </Routes>
);

const SpeciesRoutes = () => (
  <Routes>
    <Route path="SpeciesGravewalker" element={<SpeciesGravewalker />} />
    <Route path="SpeciesHumanity" element={<SpeciesHumanity />} />
    <Route path="SpeciesLifedrinker" element={<SpeciesLifedrinker />} />
    <Route path="SpeciesManaforged" element={<SpeciesManaforged />} />
    <Route path="SpeciesScytherai" element={<SpeciesScytherai />} />
    <Route path="SpeciesSeraphiend" element={<SpeciesSeraphiend />} />
    <Route path="SpeciesSkinWalker" element={<SpeciesSkinWalker />} />
    <Route path="SpeciesStarborn" element={<SpeciesStarborn />} />
    <Route path="SpeciesSunseed" element={<SpeciesSunseed />} />
    <Route path="SpeciesVermisCorpus" element={<SpeciesVermisCorpus />} />
    <Route path="SpeciesWinterWitch" element={<SpeciesWinterWitch />} />
  </Routes>
);

export default App;
