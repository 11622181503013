import React, { createContext, useState, useEffect, useContext, useMemo } from 'react';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true); // Start loading true for initial check
  const [token, setToken] = useState(localStorage.getItem('auth_token'));
  const [authError, setAuthError] = useState(null);

  // Use production URL when in production, localhost for development
  const API_URL = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
    ? 'http://localhost:3001/api'
    : 'https://firstveil.com/api';  // Replace with your actual production API URL

  const fetchUserProfile = async (currentToken) => {
    // Only attempt if we have a token passed to the function
    if (!currentToken) {
      return null;
    }
    
    // Don't set loading here, let the caller manage it if needed
    try {
      console.log('Fetching user profile with token...');
      
      const response = await fetch(`${API_URL}/profile`, {
        method: 'GET',
        headers: {
          'x-auth-token': currentToken, // Use the passed token
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });

      console.log('Profile response status:', response.status);

      if (response.ok) {
        const userData = await response.json();
        console.log('User data received:', userData);
        setCurrentUser(userData);
        setAuthError(null);
        return userData;
      } else {
        if (response.status === 401 || response.status === 403) {
          console.error('Authentication error during fetch:', response.status);
          // Don't call logoutInternal here directly, let the caller handle it
        } else {
          console.error('API error during fetch:', response.status);
          setAuthError(`Server error (${response.status}). Please try again later.`);
        }
        // Clear user if fetch fails with auth error
        setCurrentUser(null); 
        localStorage.removeItem('auth_token');
        setToken(null);
        return null;
      }
    } catch (error) {
      console.error('Error fetching user profile:', error.message);
      setAuthError('Network error. Please check your connection and try again.');
      // Clear user on network error during initial fetch
      setCurrentUser(null); 
      localStorage.removeItem('auth_token');
      setToken(null);
      return null;
    }
  };

  // Initialize auth once on first load
  useEffect(() => {
    const initAuth = async () => {
      const initialToken = localStorage.getItem('auth_token');
      console.log('Initializing Auth, token found:', !!initialToken);
      if (initialToken) {
        // setLoading(true) is already set initially
        await fetchUserProfile(initialToken);
      } 
      // Crucially, set loading to false *after* the attempt, regardless of token presence
      setLoading(false); 
    };
    
    initAuth();
  }, []); // Empty dependency array - run only on mount

  const login = async (email, password) => {
    // Ensure loading state is true at the start
    setLoading(true);
    setAuthError(null);
    try {
      console.log('Attempting login for:', email);
      
      const response = await fetch(`${API_URL}/login`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, password })
      });

      if (response.status === 204) {
        throw new Error('Login failed: No content received');
      }

      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.message || 'Login failed');
      }

      console.log('Login successful, processing data...');
      
      // 1. Set user state
      setCurrentUser(data.user);
      
      // 2. Store token in localStorage
      localStorage.setItem('auth_token', data.token);
      
      // 3. Update token state *last*
      setToken(data.token);
      
      setAuthError(null); // Clear any previous errors
      setLoading(false); // Set loading false at the very end of success
      return data.user;
    } catch (error) {
      console.error('Login error:', error.message);
      setAuthError(error.message || 'Login failed. Please check your credentials.');
      // Ensure user/token are cleared on error
      setCurrentUser(null);
      localStorage.removeItem('auth_token');
      setToken(null);
      setLoading(false); // Set loading false on error
      throw error; // Re-throw for the component to handle if needed
    }
  };

  const signup = async (displayName, email, password) => {
    setLoading(true);
    setAuthError(null);
    try {
      console.log('Attempting signup for:', email);
      
      const response = await fetch(`${API_URL}/register`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 
          displayName, 
          email, 
          password,
          role: 'commoner' // Default role for new users
        })
      });

      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.message || 'Signup failed');
      }

      console.log('Signup successful, processing data...');
      
      // 1. Set user state
      setCurrentUser(data.user);
      
      // 2. Store token in localStorage
      localStorage.setItem('auth_token', data.token);
      
      // 3. Update token state *last*
      setToken(data.token);

      setAuthError(null);
      setLoading(false); // Set loading false at the end
      return data.user;
    } catch (error) {
      console.error('Signup error:', error.message);
      setAuthError(error.message || 'Signup failed. Please try again.');
      // Ensure user/token are cleared on error
      setCurrentUser(null);
      localStorage.removeItem('auth_token');
      setToken(null);
      setLoading(false); // Set loading false on error
      throw error;
    }
  };

  // Internal logout function - doesn't trigger state updates that cause re-renders
  const logoutInternal = () => {
    localStorage.removeItem('auth_token');
    setToken(null);
    setCurrentUser(null);
    setAuthError(null);
  };

  // External logout function - the one exposed to components
  const logout = () => {
    console.log('Logging out user');
    setLoading(true); // Indicate loading during logout process
    logoutInternal();
    // Setting loading false immediately after internal changes
    setLoading(false);
  };

  // Wrap the value object in useMemo
  const value = useMemo(() => ({
    currentUser,
    loading,
    token,
    authError,
    login,
    logout,
    signup
    // No need to include the functions (login, logout, signup) 
    // as they are stable due to how they are defined in the component scope.
    // If they were recreated based on props or state not in the deps, 
    // they might need useCallback, but here it's fine.
  }), [currentUser, loading, token, authError]); // Add dependencies

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;